import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './styles.scss';
import Workspace from './components/Workspace';
import MeetingRooms from './components/MeetingRooms';
import Offices from './components/Offices';
import OtherServices from './components/OtherServices';
import { useState } from 'react';
import Select from '../ui/Select';

const components = [
    <Workspace />,
    <MeetingRooms />,
    <Offices />,
    <OtherServices />
]

const options = [
    { value: 0, label: 'Рабочие места' },
    { value: 1, label: 'Meeting-rooms' },
    { value: 2, label: 'Офисы' },
    { value: 3, label: 'Прочие услуги' }
]

const Rates = () => {
    const [index, setIndex] = useState(0);

    return (
        <div className='rates' id='rates'>
            <div className='rates__content'>
                <h2 className='rates__content__title'>Тарифы</h2>

                <Tabs className='rates__content__tabs'>
                    <TabList className="rates__content__tabs__list">
                        <Tab className="rates__content__tabs__list__tab rates__content__tabs__list__tab_first" selectedClassName='rates__content__tabs__list__tab_selected'>Рабочие места</Tab>
                        <Tab className="rates__content__tabs__list__tab" selectedClassName='rates__content__tabs__list__tab_selected'>Meeting-rooms</Tab>
                        <Tab className="rates__content__tabs__list__tab" selectedClassName='rates__content__tabs__list__tab_selected'>Офисы</Tab>
                        <Tab className="rates__content__tabs__list__tab" selectedClassName='rates__content__tabs__list__tab_selected'>Прочие услуги</Tab>
                    </TabList>

                    <TabPanel>
                        <Workspace />
                    </TabPanel>
                    <TabPanel>
                        <MeetingRooms />
                    </TabPanel>
                    <TabPanel>
                        <Offices />
                    </TabPanel>
                    <TabPanel>
                        <OtherServices />
                    </TabPanel>
                </Tabs>

                <div className='rates__content_mobile'>
                    <Select 
                    options={options} 
                    defaultValue={options[0]}
                    onChange={({value}) => setIndex(value)}
                    />

                    {components[index]}
                </div>
            </div>
        </div>
    )
}

export default Rates;
import './styles.scss';
import Button from '../ui/Button';
import Slider from 'react-slick';
import CoworkingImg1 from '../../assets/images/coworking-1.png'
import CoworkingImg2 from '../../assets/images/coworking-2.png'
import CoworkingImg3 from '../../assets/images/coworking-3.png'

const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: 'custom-dots',
    autoplay: true,
    autoplaySpeed: 5000
};

const ContactForm = () => {
    return (
        <div className='contact-form' id='contact-form'>
            <div className='contact-form__form-background'/>

            <div className='contact-form__invisible-wrapper'>
                <div className='contact-form__form-wrapper'>
                    <div className='contact-form__form-wrapper__form'>
                        <h2 className='contact-form__form-wrapper__form__title'>Оставь контакты</h2>
                        <p className='contact-form__form-wrapper__form__subtitle'>И получите приглашение на <b>ТЕСТ ДРАЙВ</b> коворкинга</p>

                        <Button fullWidth href="https://b24-h5bysw.bitrix24.site/crm_form_pex69/">Пройти тест драйв</Button>

                        <p className='contact-form__form-wrapper__form__agreement'>Нажимая на кнопку, я соглашаюсь на обработку персональных данных и с условиями акции.</p>
                    </div>
                </div>
            </div>



            <div className='contact-form__slider'>
                <Slider {...sliderSettings}>
                    <div className='contact-form__slider__page'>
                        <div className='contact-form__slider__page__gradient'></div>
                        <img src={CoworkingImg1} alt='coworking-1' />
                        <p>Collaboration area – лучшее пространство для креативных решений
                            Отлично подойдет как для съемок, так и для деловых встреч</p>
                    </div>
                    <div className='contact-form__slider__page'>
                        <div className='contact-form__slider__page__gradient'></div>
                        <img src={CoworkingImg2} alt='coworking-2' />
                        <p>
                            Кофейня «Location» - уютная кофейня со вкуснейшим кофе и не менее вкусными обедами
                        </p>
                    </div>
                    <div className='contact-form__slider__page'>
                        <div className='contact-form__slider__page__gradient'></div>
                        <img src={CoworkingImg3} alt='coworking-3' />
                        <p>Фитнес зона – ведь правда классно сделать перерыв и уделить время спорту </p>
                    </div>
                </Slider>
            </div>
        </div>
    )
}

export default ContactForm;
import './styles.scss';
import Button from '../ui/Button';
import { useState } from 'react';

const items = [
    {
        title: "JAS COWORKING",
        brand: "Технологичное пространство для бизнеса.",
        imageClass: 'first-img'
    },
    {
        title: "Акция 1+1",
        brand: "Забронируй 1 час митинг рума и получи 2-ой час в подарок. * Для новых резидентов",
        imageClass: 'second-img'
    },
    {
        title: "Акция 2+1",
        brand: "Приобрети абонемент резиндентсва на 2 месяца и получи 3-ий в подарок",
        imageClass: 'third-img'
    }
]

const FirstSection = () => {
    const [index, setIndex] = useState(0);

    return (
        <div className='first-section__wrapper'>
            {/* <div className={`first-section`}> */}
            <div className={`first-section ${items[index].imageClass}`}>
                <div className='first-section__content'>
                    <p className='first-section__content__label'>Coworking</p>
                    <h1 className='first-section__content__title'>{items[index].title}</h1>
                    <p className='first-section__content__brand' dangerouslySetInnerHTML={{ __html: items[index].brand }} />
                    <Button>Забронировать</Button>
                </div>
            </div>
        </div>
    )
}

export default FirstSection;
import './styles.scss';

const Advantages = () => {
    return (
        <div className='advantages'>
            <div className='advantages__dots'></div>

            <div className='advantages__content'>
                <h1 className='advantages__content__title'>
                Преимущества
                </h1>

                <div className='advantages__content__list'>
                    <div className='advantages__content__list__item'>
                        <p className='advantages__content__list__item__title'>24/7</p>
                        <p className='advantages__content__list__item__text'>доступ к услугам коворкинга</p>
                    </div>

                    <div className='advantages__content__list__item'>
                        <p className='advantages__content__list__item__title'>200</p>
                        <p className='advantages__content__list__item__text'>рабочих мест</p>
                    </div>

                    <div className='advantages__content__list__item'>
                        <p className='advantages__content__list__item__title'>Комьюнити</p>
                        <p className='advantages__content__list__item__text'>предприниматели, инвесторы, стартеры</p>
                    </div>

                    <div className='advantages__content__list__item'>
                        <p className='advantages__content__list__item__title'>Мероприятия</p>
                        <p className='advantages__content__list__item__text'>мастер классы, хакатаны, открытый микрофон, мастер майнды</p>
                    </div>

                    <div className='advantages__content__list__item'>
                        <p className='advantages__content__list__item__title'>Спец предложения </p>
                        <p className='advantages__content__list__item__text'>на продукты экосистемы</p>
                    </div>

                    <div className='advantages__content__list__item advantages__content__list__item_last'>
                        <p className='advantages__content__list__item__title'>Дополнительные услуги</p>
                        <p className='advantages__content__list__item__text'>кэтеринг, аренда оборудования, услуги ассистента</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Advantages;
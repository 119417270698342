import './styles.scss';
import { ReactComponent as Logo } from '../../assets/logo/jva-logo.svg';
import { ReactComponent as MenuIcon } from '../../assets/icons/menu.svg';
import Button from '../ui/Button';
import { useState } from 'react';
import Menu from '../Menu';

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const handleSetMenuOpen = (open) => {
        document.body.style.overflow = menuOpen ? 'auto' : 'hidden';

        setMenuOpen(open);
    }

    return (
        <header className='header'>
            <div className='header__content'>
                <Logo />
                <div className='header__content__controls'>
                    <div className='header__content__controls__links'>
                        <a href='#ecosystem'>О нас</a>
                        <a href='#for-whom'>Для кого</a>
                        <a href='#rates'>Тарифы</a>
                        <a href='#contacts'>Контакты</a>
                    </div>
                    <Button>Забронировать</Button>
                </div>

                <div className='header__menu'>
                    <MenuIcon onClick={() => handleSetMenuOpen(!menuOpen)} />
                </div>
            </div>

            <Menu open={menuOpen} setOpen={handleSetMenuOpen} />
        </header>
    )
}

export default Header;
import { useId } from 'react';
import ForWhomCard from './components/ForWhomCard';
import './styles.scss';
import Memoji1 from '../../assets/images/memoji-1.png';
import Memoji2 from '../../assets/images/memoji-2.png';
import Memoji3 from '../../assets/images/memoji-3.png';

const cards = [
    {   
        img: Memoji1,
        caption: "Для стартапов",
        color: "#4045BA",
        text: "Приглашение на питч дей, нетворкинг с инвесторами и партнерами, удобное пространство для брейншторма"
    },
    {   
        img: Memoji2,
        caption: "Для предпринимателей",
        color: "#333333",
        text: "Комфортное размещение, нетворкинг с бизнесом и стартапами, специальные условия на дополнительные услуги до 50%."
    },
    {   
        img: Memoji3,
        caption: "Для фрилансеров",
        color: "#FFBC39",
        text: "Круглосуточный доступ, комьюнити единомышленников, участие на мероприятиях"
    }
]

const ForWhom = () => {
    const prefix = useId();

    return (
        <div className='for-whom' id='for-whom'>
            <div className='for-whom__content'>
                <h1 className='for-whom__content__title'>ДЛЯ КОГО</h1>

                <div className='for-whom__content__cards'>
                {cards.map((card, index) => (
                    <ForWhomCard key={`${prefix}-${index}`} card={card}/>
                ))}
                </div>
            </div>

            <div className='for-whom__dots'></div>
        </div>
    )
}

export default ForWhom;
import './styles.scss';
import { ReactComponent as LogoColor } from '../../assets/logo/jva-logo-color.svg';
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../assets/icons/instagram.svg';

const Footer = () => {
    return (
        <footer className='footer'>
            <LogoColor />
            <p className='footer__brand'> Jas Coworking - технологичное пространство для бизнеса</p>

            <div className='footer__links'>
                <a href='#ecosystem'>О нас</a>
                <a href='#for-whom'>Для кого</a>
                <a href='#rates'>Тарифы</a>
                <a href='#contacts'>Контакты</a>
            </div>

            <div className='footer__social'>
                <a href='https://www.facebook.com/Jas-Ventures-Almaty-107199215261573' target="_blank" rel="noreferrer noopener"><FacebookIcon /></a>
                <a href='https://www.instagram.com/jas.ventures/' target="_blank" rel="noreferrer noopener"><InstagramIcon /></a>
            </div>
        </footer>
    )
}

export default Footer;
import { useId, useState } from 'react';
import Slider from 'react-slick';
import './styles.scss';
import Card from '../Card';
import OpenspaceImg1 from '../../../../assets/images/open-space-1.png'
import { ReactComponent as CheckIcon } from '../../../../assets/icons/check.svg';
import { ReactComponent as ArrowLeftIcon } from '../../../../assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icons/arrow-right.svg';
import { ReactComponent as PurpleArrowLeftIcon } from '../../../../assets/icons/purple-arrow-left.svg';
import { ReactComponent as PurpleArrowRightIcon } from '../../../../assets/icons/purple-arrow-right.svg';
import Select from '../../../ui/Select';

const FirstSliderPrevArrow = ({ onClick }) => {
    return (
        <div
            className="first-slider-arrow first-slider-arrow-prev"
            onClick={onClick}
        >
            <ArrowLeftIcon />
        </div>
    )
}

const FirstSliderNextArrow = ({ onClick }) => {
    return (
        <div
            className="first-slider-arrow first-slider-arrow-next"
            onClick={onClick}
        >
            <ArrowRightIcon />
        </div>
    )
}

const SecondSliderPrevArrow = ({ onClick }) => {
    return (
        <div
            className="second-slider-arrow second-slider-arrow-prev"
            onClick={onClick}
        >
            <PurpleArrowLeftIcon />
        </div>
    )
}

const SecondSliderNextArrow = ({ onClick }) => {
    return (
        <div
            className="second-slider-arrow second-slider-arrow-next"
            onClick={onClick}
        >
            <PurpleArrowRightIcon />
        </div>
    )
}

const firstSliderSettings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <FirstSliderPrevArrow />,
    nextArrow: <FirstSliderNextArrow />,
    dotsClass: 'first-slider-dots'
};

const secondSliderSettings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 10000,
    slidesToShow: 3,
    slidesToScroll: 3,
    prevArrow: <SecondSliderPrevArrow />,
    nextArrow: <SecondSliderNextArrow />
};

const tariffs = [
    {
        name: "Пол дня",
        services: [
            "1(одно) не закрепленное рабочее место.",
            "Доступ в коворкинг с 09:00 до 18:00",
            "Интернет 200 мб/с",
            "Услуги Администратора",
            "Кухня",
            "Фитнес зал",
            "Душевые",
            "Зоны для общего пользования: кофейня, collaboration area, съемки в открытых зонах.",
            "Клининг"
        ],
        price: 7500
    },
    {
        name: "Дневной",
        services: [
            "1(одно) не закрепленное рабочее место.",
            "Доступ в коворкинг с 09:00 до 18:00",
            "Интернет 200 мб/с",
            "Услуги Администратора",
            "Кухня",
            "Фитнес зал",
            "Душевые",
            "Зоны для общего пользования: кофейня, collaboration area, съемки в открытых зонах.",
            "Клининг"
        ],
        price: 15000
    },
    {
        name: "10 дней подряд",
        services: [
            "1(одно) не закрепленное рабочее место.",
            "Доступ в коворкинг 24/7, 10 дней подряд.",
            "Интернет 200 мб/с",
            "1 (один) час использования meeting-room до 4 человек",
            "Распечатка 20 ч/б страниц",
            "Услуги Администратора",
            "Кухня",
            "Фитнес зал",
            "Душевые",
            "Зоны для общего пользования: кофейня, collaboration area, съемки в открытых зонах.",
            "Клининг"
        ],
        price: 60000
    },
    {
        name: "Не Фиксированные",
        services: [
            "1(одно) не фиксированное рабочее место.",
            "Доступ в коворкинг 24/7",
            "Интернет 200 мб/с",
            "3 (три) часа использования meeting-room до 4 человек",
            "Распечатка 50 ч/б страниц",
            "Посещение всех мероприятии организованные JAS Coworking",
            "Специальные условия по всем продуктам Jas Ventures Almaty",
            "Услуги Администратора",
            "Кухня",
            "Фитнес зал",
            "Душевые",
            "Зоны для общего пользования: кофейня, collaboration area, съемки в открытых зонах.",
            "Коммунальные услуги"
        ],
        price: 120000
    },
    {
        name: "Фиксированные",
        services: [
            "1(одно) фиксированное рабочее место.",
            "Доступ в коворкинг 24/7",
            "Интернет 200 мб/с",
            "3 (три) часа использования meeting-room до 4 человек",
            "Распечатка 50 ч/б страниц",
            "Посещение всех мероприятии организованные JAS Coworking",
            "Специальные условия по всем продуктам Jas Ventures Almaty",
            "Услуги Администратора",
            "Кухня",
            "Фитнес зал",
            "Душевые",
            "Клининг",
            "Локеры",
            "Зоны для общего пользования: кофейня, collaboration area, съемки в открытых зонах.",
            "Коммунальные услуги"
        ],
        price: 150000
    },
    {
        name: "Premium",
        services: [
            "1(одно) фиксированное рабочее место в «Premium» зоне.",
            "Доступ в коворкинг 24/7",
            "Интернет 200 мб/с",
            "3 (три) часа использования meeting-room до 4 человек",
            "Распечатка 50 ч/б страниц",
            "Посещение всех мероприятии организованные JAS Coworking",
            "Специальные условия по всем продуктам Jas Ventures Almaty",
            "Услуги Администратора",
            "Кухня",
            "Фитнес зал",
            "Душевые",
            "Клининг",
            "Локеры",
            "Зоны для общего пользования: кофейня, collaboration area, съемки в открытых зонах.",
            "Коммунальные услуги"
        ],
        price: 175000,
        is_premium: true,
        is_last: true
    },
]

const options = [
    { value: 0, label: 'Пол дня' },
    { value: 1, label: 'Дневной' },
    { value: 2, label: '10 дней подряд' },
    { value: 3, label: 'Не Фиксированные' },
    { value: 4, label: 'Фиксированные' },
    { value: 5, label: 'Premium' },
]

const Workspace = () => {
    const prefix = useId();

    const [index, setIndex] = useState(0);

    return (
        <div className='workspace'>
            <div className='open-space'>
                <div className='open-space__block'>
                    <h2 className='open-space__block__title'>Open space</h2>
                    <p className='open-space__block__subtitle'>Более 200 рабочих мест. Лучшее пространство по специально продуманной системе шумоизоляции.</p>

                    <ul>
                        <li><CheckIcon /> <p>Посещение всех мероприятий организованные JAS Ventures Almaty</p></li>
                        <li><CheckIcon /> <p>Высококачественная мебель по европейскому стандарту</p></li>
                        <li className='last'><CheckIcon /> <p>Бесплатный доступ к meeting-room (3 часа до 4 человек)</p></li>
                    </ul>
                </div>

                <div className='open-space__slider'>
                    <Slider {...firstSliderSettings}>
                        <div className='open-space__slider__page'>
                            <div className='open-space__slider__page__gradient' />
                            <img src={OpenspaceImg1} alt='open-space-1' />
                        </div>
                        <div className='open-space__slider__page'>
                            <div className='open-space__slider__page__gradient' />
                            <img src={OpenspaceImg1} alt='open-space-1' />
                        </div>
                        <div className='open-space__slider__page'>
                            <div className='open-space__slider__page__gradient' />
                            <img src={OpenspaceImg1} alt='open-space-1' />
                        </div>
                        <div className='open-space__slider__page'>
                            <div className='open-space__slider__page__gradient' />
                            <img src={OpenspaceImg1} alt='open-space-1' />
                        </div>
                        <div className='open-space__slider__page'>
                            <div className='open-space__slider__page__gradient' />
                            <img src={OpenspaceImg1} alt='open-space-1' />
                        </div>
                    </Slider>
                </div>
            </div>

            <div className='second-slider'>
                <Slider {...secondSliderSettings}>
                    {tariffs.map((tariff, index) => (
                        <Card key={`${prefix}-${index + 1}`} tariff={tariff} index={index + 1} />
                    ))}
                </Slider>
            </div>

            <div className='open-space__tariffs'>
                <Select
                    options={options}
                    defaultValue={options[0]}
                    onChange={({ value }) => setIndex(value)}
                />
                <Card tariff={tariffs[index]} />
            </div>
        </div>
    )
}

export default Workspace;
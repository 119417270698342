import './styles.scss';
import { ReactComponent as ArrowLeftIcon } from '../../../../assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icons/arrow-right.svg';
import EventAreaImg from '../../../../assets/images/event-area.png';
import AmethystImg from '../../../../assets/images/amethyst.png';
import BerylImg from '../../../../assets/images/beryl.png';
import CoralImg from '../../../../assets/images/coral.png';
import DiamondImg from '../../../../assets/images/diamond.png';
import EmeraldImg from '../../../../assets/images/emerald.png';
import BetaImg from '../../../../assets/images/beta.png';
import AlphaImg from '../../../../assets/images/alpha.png';
import GammaImg from '../../../../assets/images/gamma.png';
import Slider from 'react-slick';
import Button from '../../../ui/Button';
import Select from '../../../ui/Select';
import { useState, useId } from 'react';


const tariffs = [
    {
        name: 'EVENT AREA',
        image: EventAreaImg,
        square: 50,
        services: 'Рассадка в виде амфитеатра, проектор, мебель, клининг',
        additionally: 'колонки, микрофоны, петличка.',
        capacity: 40,
        price: 20000,
        minTime: 3
    },
    {
        name: 'A-AMETHYST',
        image: AmethystImg,
        square: 58,
        services: 'Проектор, столы, стулья, шкафчик для верхней одежды, клининг',
        capacity: 25,
        price: 15000
    },
    {
        name: 'B-BERYL',
        image: BerylImg,
        square: 58,
        services: 'Проектор, столы, стулья, шкафчик для верхней одежды, клининг',
        capacity: 25,
        price: 15000
    },
    {
        name: 'C-CORAL',
        image: CoralImg,
        square: 58,
        services: 'Проектор, столы, стулья, шкафчик для верхней одежды, клининг',
        capacity: 20,
        price: 13000
    },
    {
        name: 'D-DIAMOND',
        image: DiamondImg,
        square: 100,
        services: 'Проектор, столы, стулья, шкафчик для верхней одежды, клининг',
        capacity: 100,
        price: 20000
    },
    {
        name: 'E-EMERALD',
        image: EmeraldImg,
        square: 100,
        services: 'Телевизор, столы, стулья, шкафчик для верхней одежды, клининг',
        capacity: 30,
        price: 15000
    },
    {
        x: 2,
        name: 'МЕСТНЫЙ BETA',
        image: BetaImg,
        services: 'Cтолы, стулья, клининг',
        residentPrice: 2000,
        guestPrice: 4000
    },
    {
        x: 4,
        name: 'МЕСТНЫЙ ALPHA',
        image: AlphaImg,
        services: 'Cтолы, стулья, клининг',
        residentPrice: 4000,
        guestPrice: 6000
    },
    {
        x: 6,
        name: 'МЕСТНЫЙ GAMMA',
        image: GammaImg,
        services: 'Cтолы, стулья, клининг',
        residentPrice: 2000,
        guestPrice: 4000
    }
]

const options = [
    { value: 0, label: 'EVENT AREA' },
    { value: 1, label: 'A-AMETHYST' },
    { value: 2, label: 'B-BERYL' },
    { value: 3, label: 'C-CORAL' },
    { value: 4, label: 'D-DIAMOND' },
    { value: 5, label: 'E-EMERALD' },
    { value: 6, label: '2X МЕСТНЫЙ BETA' },
    { value: 7, label: '4X МЕСТНЫЙ ALPHA' },
    { value: 8, label: '6X МЕСТНЫЙ GAMMA' },
]

const SliderPrevArrow = ({ onClick }) => {
    return (
        <div
            className="slider-arrow slider-arrow-prev"
            onClick={onClick}
        >
            <ArrowLeftIcon />
        </div>
    )
}

const SliderNextArrow = ({ onClick }) => {
    return (
        <div
            className="slider-arrow slider-arrow-next"
            onClick={onClick}
        >
            <ArrowRightIcon />
        </div>
    )
}

const sliderSettings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <SliderPrevArrow />,
    nextArrow: <SliderNextArrow />,
    dotsClass: 'meeting-rooms-slider-dots'
};

const MeetingRooms = () => {
    const [currentTariff, setCurrentTariff] = useState(0);
    const prefix = useId();

    const handleChangeSlider = (currentSlide, next) => {
        setCurrentTariff(next);
    }

    return (
        <div className='meeting-rooms'>
            <div className='meeting-rooms__slider'>
                <Slider
                    {...sliderSettings}
                    beforeChange={handleChangeSlider}
                >
                    {tariffs.map((tariff, index) => (
                        <div key={`${prefix}-${index}`} className='meeting-rooms__slider__page'>
                            <div className='meeting-rooms__slider__page__gradient' />
                            <img src={tariff.image} alt={tariff.name} />
                        </div>
                    ))}

                </Slider>
            </div>

            <div className='meeting-rooms_mobile'>
                <p>
                    Отлично подойдёт для проведения мастер-классов, конференций и мероприятий со вместимостью от 2 до 70 человек
                </p>

                <Select
                    options={options}
                    defaultValue={options[0]}
                    onChange={({ value }) => setCurrentTariff(value)}
                />
            </div>

            <div className='meeting-rooms__block'>
                <img src={tariffs[currentTariff].image} alt="location" className='meeting-rooms__block__image'/>

                <h2 className='meeting-rooms__block__title'>
                    <span>{tariffs[currentTariff]?.x && `${tariffs[currentTariff]?.x}X`}</span> {tariffs[currentTariff].name}
                </h2>

                {/* {tariffs[currentTariff].square && (
                    <>
                        <p className='meeting-rooms__block__label'>Площадь</p>
                        <p className='meeting-rooms__block__value'><b>{tariffs[currentTariff].square}</b> м<sup>2</sup></p>
                    </>
                )} */}

                <p className='meeting-rooms__block__label'>В стоимость входит:</p>
                <p className='meeting-rooms__block__value'>{tariffs[currentTariff].services}</p>

                {tariffs[currentTariff].additionally && (
                    <p className='meeting-rooms__block__additionally'><b>За дополнительную плату:</b> {tariffs[currentTariff].additionally}</p>
                )}

                {tariffs[currentTariff].capacity && (
                    <>
                        <p className='meeting-rooms__block__label'>Вместимость</p>
                        <p className='meeting-rooms__block__value'>Максимальная вместимость до <b>{tariffs[currentTariff].capacity} чел.</b></p>
                    </>
                )}

                {tariffs[currentTariff].residentPrice && (
                    <>
                        <p className='meeting-rooms__block__price meeting-rooms__block__price_no_flex'>{tariffs[currentTariff].residentPrice} тг</p>
                        <p className='meeting-rooms__block__price__label'>Для резидентов <b>JVA</b></p>
                    </>
                )}

                {tariffs[currentTariff].guestPrice && (
                    <>
                        <p className='meeting-rooms__block__price meeting-rooms__block__price_no_flex'>{tariffs[currentTariff].guestPrice} тг</p>
                        <p className='meeting-rooms__block__price__label meeting-rooms__block__price__label_flex'>Для гостей <b>JVA</b></p>
                    </>
                )}

                {tariffs[currentTariff].price && (
                    <p className='meeting-rooms__block__price'>{tariffs[currentTariff].price} тг / ч</p>
                )}
                <Button href="https://b24-h5bysw.bitrix24.site/crm_form_fcocs/" fullWidth>Забронировать</Button>
            </div>
        </div>
    )
}

export default MeetingRooms;
import './styles.scss';
import clsx from 'clsx';
import Button from '../../../ui/Button';
import { ReactComponent as GreenCheck } from '../../../../assets/icons/green-check.svg';
import { ReactComponent as YellowCheck } from '../../../../assets/icons/yellow-check.svg';
import { useId } from 'react';

const Card = ({ index, tariff }) => {
    const prefix = useId();

    return (
        <div className={clsx('card', {
            'card_no_margin': index % 3 === 0,
            'card_premium': tariff.is_premium,
            'card_last_three': index > 3,
            'card_last': tariff.is_last
        })}>
            <h3 className='card__title'>{tariff.name}</h3>

            <div className='card__divider card__divider_mobile'></div>

            <ul className='card__services'>
                {tariff.services.map((service, index) => (
                    <li key={`${prefix}-${index}`}>{tariff.is_premium ? <YellowCheck /> : <GreenCheck />} <span>{service}</span></li>
                ))}
            </ul>

            <div className='card__divider'></div>

            <p className='card__price'>{tariff.price} тг</p>

            <Button
                premium={tariff.is_premium}
                href="https://b24-h5bysw.bitrix24.site/crm_form_aqjdl/"
                fullWidth
            >
                Забронировать
            </Button>
        </div>
    )
}

export default Card;
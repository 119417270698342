import './styles.scss';
import { ReactComponent as PinIcon } from '../../assets/icons/pin.svg';
import { ReactComponent as MailIcon } from '../../assets/icons/mail.svg';
import { ReactComponent as PhoneIcon } from '../../assets/icons/phone.svg';

const Contacts = () => {
    return (
        <div className='contacts' id='contacts'>
            <div className='contacts__map'></div>

            <div className='contacts__dots'></div>

            <div className='contacts__content'>
                <div className='contacts__content__block'>
                    <h2 className='contacts__content__block__title'>Наши контакты</h2>

                    <div className='contacts__content__block__content'>
                        <div className='contacts__content__block__content__row'>
                            <PinIcon />
                            <div className='contacts__content__block__content__row__text'>
                                <p className='contacts__content__block__content__row__text__label'>Адрес</p>
                                <p>Проспект Сейфуллина, 609<br /> Бостандыкский район, Алматы</p>
                            </div>
                        </div>
                        <div className='contacts__content__block__content__row'>
                            <MailIcon />
                            <div className='contacts__content__block__content__row__text'>
                                <p className='contacts__content__block__content__row__text__label'>Электронный адрес</p>
                                <p>coworking@jva.vc</p>
                            </div>
                        </div>
                        <div className='contacts__content__block__content__row'>
                            <PhoneIcon />
                            <div className='contacts__content__block__content__row__text'>
                                <p className='contacts__content__block__content__row__text__label'>Номер телефона</p>
                                <p>+7 (708) 359-08-69</p>
                                <span>Ресепшн</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contacts;
import './styles.scss';
import { ReactComponent as ArrowLeftIcon } from '../../../../assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icons/arrow-right.svg';
import { ReactComponent as YellowCheck } from '../../../../assets/icons/yellow-check.svg';
import OfficeImg1 from '../../../../assets/images/office-1.png';
import Slider from 'react-slick';
import Button from '../../../ui/Button';

const SliderPrevArrow = ({ onClick }) => {
    return (
        <div
            className="slider-arrow slider-arrow-prev"
            onClick={onClick}
        >
            <ArrowLeftIcon />
        </div>
    )
}

const SliderNextArrow = ({ onClick }) => {
    return (
        <div
            className="slider-arrow slider-arrow-next"
            onClick={onClick}
        >
            <ArrowRightIcon />
        </div>
    )
}

const sliderSettings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <SliderPrevArrow />,
    nextArrow: <SliderNextArrow />,
    dotsClass: 'offices-slider-dots'
};

const Offices = () => {
    return (
        <div className='offices'>
            <div className='offices__slider'>
                <Slider {...sliderSettings}>
                    <div className='offices__slider__page'>
                        <div className='offices__slider__page__gradient' />
                        <img src={OfficeImg1} alt='office-1' />
                    </div>
                    <div className='offices__slider__page'>
                        <div className='offices__slider__page__gradient' />
                        <img src={OfficeImg1} alt='office-1' />
                    </div>
                    <div className='offices__slider__page'>
                        <div className='offices__slider__page__gradient' />
                        <img src={OfficeImg1} alt='office-1' />
                    </div>
                    <div className='offices__slider__page'>
                        <div className='offices__slider__page__gradient' />
                        <img src={OfficeImg1} alt='office-1' />
                    </div>
                    <div className='offices__slider__page'>
                        <div className='offices__slider__page__gradient' />
                        <img src={OfficeImg1} alt='office-1' />
                    </div>
                </Slider>
            </div>

            <div className='offices__block'>
                <h2 className='offices__block__title'>Office</h2>
                <p className='offices__block__subtitle'>Офисы – квадратура от 20 до 60 квм вместимостью от 2 до 10 человек</p>

                <ul>
                    <li><YellowCheck /> <p>Доступ 24/7</p></li>
                    <li><YellowCheck /> <p>Высокоскоростной интернет - 200 Mбит/с</p></li>
                    <li><YellowCheck /> <p>Полностью оборудованная мебель</p></li>
                </ul>

                <Button className="offices__block__btn" href="https://b24-h5bysw.bitrix24.site/crm_form_dts0a/">Забронировать</Button>
            </div>

            <Button
                className="offices__block__btn_mobile"
                href="https://b24-h5bysw.bitrix24.site/crm_form_dts0a/"
                fullWidth
            >
                Забронировать
            </Button>
        </div>
    )
}

export default Offices;
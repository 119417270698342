import Button from '../../../ui/Button';
import './styles.scss';

const ForWhomCard = ({card}) => {
    return (
        <div className='for-whom-card'>
            <img src={card.img} alt='card-img' className='for-whom-card__img'/>
            <p className='for-whom-card__caption' style={{color: card.color}}>{card.caption}</p>
            <p className='for-whom-card__text'>{card.text}</p>
            <Button fullWidth>Забронировать</Button>
        </div>
    )
}

export default ForWhomCard;
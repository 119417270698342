import clsx from 'clsx';
import './styles.scss'

const Button = ({ children, fullWidth, premium, href, className }) => {
    const handleClick = () => {
        const _anchor = href || '#contact-form';
        window.location.href = _anchor;
    }

    return (
        <button
            className={clsx('btn', { 'fullwidth': fullWidth, 'premium': premium }, className)}
            onClick={handleClick}
        >
            {children}
        </button>)
}

export default Button;
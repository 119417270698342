import './styles.scss';
import Select from "react-select";
import {ReactComponent as DropdownIcon} from '../../../assets/icons/dropdown-arrow.svg';

const CustomSelect = (props) => {
    return <Select
        {...props}
        classNamePrefix='custom-select'
        isSearchable={false}
        components={{
            DropdownIndicator: DropdownIcon
        }}
    />
}

export default CustomSelect;
import './styles.scss';
import { ReactComponent as CaseIcon } from '../../assets/icons/case.svg';

const EcoSystem = () => {
    return (
        <div className='ecosystem' id="ecosystem">
            <div className='ecosystem__dots'></div>
            <div className='ecosystem__shape'></div>
            
            <div className='ecosystem__content'>
                <div className='ecosystem__content__text'>
                    <h1 className='ecosystem__content__text__title'>НАША ЭКОСИСТЕМА</h1>
                    <p className='ecosystem__content__text__subtitle'><strong>Jas Coworking</strong> - технологичное пространство в экосистеме <strong>Jas Ventures.</strong></p>
                </div>

                <div className='ecosystem__content__step_block'>
                    <div className='ecosystem__content__step_block__item'>
                        <div className='ecosystem__content__step_block__item__icon'>
                            <CaseIcon />
                        </div>
                        <p className='ecosystem__content__step_block__item__text'>
                            Образовательные программы
                        </p>
                    </div>

                    <div className='ecosystem__content__step_block__item'>
                        <div className='ecosystem__content__step_block__item__icon'>
                            <CaseIcon />
                        </div>
                        <p className='ecosystem__content__step_block__item__text'>
                            IT курсы
                        </p>
                    </div>

                    <div className='ecosystem__content__step_block__item'>
                        <div className='ecosystem__content__step_block__item__icon'>
                            <CaseIcon />
                        </div>
                        <p className='ecosystem__content__step_block__item__text'>
                            Клуб инвесторов и топ предпринимателей
                        </p>
                    </div>

                    <div className='ecosystem__content__step_block__item'>
                        <div className='ecosystem__content__step_block__item__icon'>
                            <CaseIcon />
                        </div>
                        <p className='ecosystem__content__step_block__item__text'>
                            Программы инкубации и акселерации
                        </p>
                    </div>

                    <div className='ecosystem__content__step_block__item'>
                        <div className='ecosystem__content__step_block__item__icon ecosystem__content__step_block__item__icon_last'>
                            <CaseIcon />
                        </div>
                        <p className='ecosystem__content__step_block__item__text'>
                            Платформа для креативной молодежи
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EcoSystem;
import clsx from 'clsx';
import './styles.scss';
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../assets/icons/instagram.svg';

const Menu = ({ open, setOpen }) => {
    const handleClickLink = (href) => {
        setOpen(false);

        setTimeout(() => {
            window.location.href = href;
        }, 300)
    }

    return (
        <div className={clsx('menu', { menu_open: open })} style={{height: window.innerHeight - 80}}>
            <div className='menu__links'>
                <a onClick={() => handleClickLink("#ecosystem")}>О нас</a>
                <a onClick={() => handleClickLink("#for-whom")}>Для кого</a>
                <a onClick={() => handleClickLink("#rates")}>Тарифы</a>
                <a onClick={() => handleClickLink("#contacts")}>Контакты</a>
            </div>
            <p className='menu__brand'>Jas Coworking - технологичное пространство для бизнеса</p>
            <div className='menu__social'>
                <a href='https://www.facebook.com/Jas-Ventures-Almaty-107199215261573' target="_blank" rel="noreferrer noopener"><FacebookIcon /></a>
                <a href='https://www.instagram.com/jas.ventures/' target="_blank" rel="noreferrer noopener"><InstagramIcon /></a>
            </div>
        </div>
    )
}

export default Menu;
import React from "react";
import FirstSection from "./components/FirstSection";
import ForWhom from "./components/ForWhom";
import Header from "./components/Header";
import EcoSystem from "./components/EcoSystem";
import Advantages from "./components/Advantages";
import ContactForm from "./components/ContactForm";
import Rates from "./components/Rates";
import Contacts from "./components/Contacts";
import Footer from "./components/Footer";

function App() {
  return (
    <React.Fragment>
      <Header />
      <FirstSection />
      <ForWhom />
      <EcoSystem />
      <Advantages />
      <ContactForm />
      <Rates />
      <Contacts />
      <Footer />
    </React.Fragment>
  );
}

export default App;

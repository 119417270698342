import './styles.scss';
import { ReactComponent as MediaIcon } from '../../../../assets/icons/media.svg'
import { ReactComponent as LockerIcon } from '../../../../assets/icons/locker.svg'

const OtherServices = () => {
    return (
        <div className='other-services'>
            <div className='other-services__section'>
                <div className='other-services__section__caption'>
                    <MediaIcon />
                    <p className='other-services__section__title'>Фото/видео съемки</p>
                </div>

                <p className='other-services__section__price'>20 000 тг / ч</p>
            </div>

            <div className='other-services__section other-services__section_last'>
                <div className='other-services__section__caption'>
                    <LockerIcon />
                    <p className='other-services__section__title'>Аренда локера</p>
                </div>

                <div className='other-services__section__price_block'>
                    <p className='other-services__section__price'>1 000 тг/неделю</p>
                    <p className='other-services__section__price other-services__section__price_last'>4 000 тг/месяц</p>
                </div>
            </div>
        </div>
    )
}

export default OtherServices;